<template>
  <Tippy :onShow="onShow" v-bind="props" theme="dropdown">
    <slot :props="props">
      <UIButton :isDisabled="isDisabled" :istooltip="istooltip" @click.prevent>
        {{ label || 'Dropdown' }}
      </UIButton>
    </slot>

    <template #content="{ hide }">
      <div class="UIInputDropdown__menu">
        <slot name="menu-before" />
        <ul class="UIInputDropdown__menu-list">
          <li v-for="(option, index) in itemsFiltered" :key="index">
            <slot name="option" :option="option">
              <UIButton
                theme="option"
                :href="isOptionObject(option) && option.href"
                @click.prevent="
                  () => {
                    onSelect(option)
                    hide()
                  }
                "
                isFull
              >
                <template
                  #icon-before
                  v-if="isOptionObject(option) && option.icon"
                >
                  <UIIcon
                    v-if="isOptionObject(option) && option.icon"
                    :name="option.icon"
                  />
                </template>
                {{ getOptionLabel(option) }}
              </UIButton>
            </slot>
          </li>
        </ul>
        <slot name="menu-after" />
      </div>
    </template>
  </Tippy>
</template>

<script lang="ts" setup>
import UIButton from '../buttons/UIButton.vue'
import { Tippy } from 'vue-tippy'
import UIIcon from '../icons/UIIcon.vue'
import { Placement } from '@popperjs/core'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
import { IUIInputDropdownOptionObject, IUIInputDropdownOption } from './types'

const isOptionObject = (
  option: unknown,
): option is IUIInputDropdownOptionObject =>
  !!option &&
  typeof option === 'object' &&
  'label' in option &&
  'value' in option

const getOptionLabel = (option: IUIInputDropdownOption) => {
  if (isOptionObject(option)) {
    return option.label
  }

  return option
}

type ITippyProps = InstanceType<typeof Tippy>['$props']

type IItem = string | number | IUIInputDropdownOptionObject

interface Props extends /* @vue-ignore */ ITippyProps {
  label?: string
  interactive?: boolean
  appendTo?: Element | 'parent' | ((ref: Element) => Element)
  offset?: [number, number]
  items: IItem[]
  istooltip?: boolean
  trigger?: string
  placement?: Placement
  isDisabled?: boolean
  duration?: [number, number]
  theme?: string
  arrow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  appendTo: () => () => document.body,
  interactive: true,
  offset: () => [0, 12],
  trigger: 'click',
  placement: 'bottom-start',
  duration: () => [0, 0],
  theme: 'dropdown',
  arrow: false,
})

const itemsFiltered = computed(() =>
  props.items.filter((item) => !isOptionObject(item) || !item.isHidden),
)

const onShow = () => {
  return !props.isDisabled
}

const emit = defineEmits<{
  (eventName: 'onSelect', value: IUIInputDropdownOption): void
}>()
const router = useRouter()
const onSelect = (value: IUIInputDropdownOption) => {
  if (!isOptionObject(value)) {
    return emit('onSelect', value)
  }

  if (value.onClick) {
    value.onClick()
  }
  if (value.to) {
    router.push(value.to)
  }
  emit('onSelect', value)
}
</script>

<style scoped lang="scss">
.UIInputDropdown__menu {
  border-radius: 0.25rem;
  overflow: hidden;
  border: 1px solid $newcolor-grey-200;
  box-shadow: 0 3px 12px 1px rgb(7 82 61 / 15%);
  background: white;
  .UIInputDropdown__menu-list {
    padding: 0.5rem 0;
    li {
      list-style: none;
    }
  }
}
</style>

<style lang="scss">
.tippy-box[data-theme~='dropdown'] {
  background: transparent;
  padding: 0;

  & > .tippy-content {
    padding: 0;
  }
}
</style>
